import React from "react";
import "../css/Projects.css";

export const Projects = () => {
  const projects = [
    {
      title: "Standup tool",
      desc: "Daily standup tool that we used at my company for months! Updated for the Dunder Mifflin team. ",
      link: "https://standup-tool.vercel.app/"
    },
    {
      title: "Meals & Recipes (Web)",
      desc: "Reciprocal to the iOS app but in React.js so I don't have to get an Apple Developer license. Mostly using React Bootstrap.",
      link: "https://meals-and-recipes-web.vercel.app/",
    },
    {
      title: "LottoJS",
      desc: "Web app that simulates playing the Lotto. Six winning numbers (within the range of 0-70) are created to start and then you keep playing until you win!",
      link: "https://lottojs.vercel.app/",
    },
    {
      title: "Alejo Minis",
      desc: "React site for a friend mini painter, includes an EmailJS contact form to help arrange commissions.",
      link: "https://alejo-minis.vercel.app/",
    },
    {
      title: "Fantasy Baseball Keepers",
      desc: "A tool for my fantasy baseball keeper league to access their players from past seasons.",
      link: "https://two-hour-schmooze-react.vercel.app/",
    },
    {
      title: "Espresso Practice",
      desc: "Learning Espresso at Peloton through a mentorship program. This is where I was introduced to Kotlin & then Espresso before becoming a contributor to the actual test repo.",
      link: "https://github.com/pl-peloton/scott-pai-emp",
    },
    {
      title: "NFL Wordle 2021",
      desc: "Python game played in a terminal that follows similar rules as Wordle but the user is guessing NFL players from the 2021 season.",
      link: "https://github.com/escosio/nfl-wordle",
    },
    {
      title: "Narwhals & Sea Turtles",
      desc: "Using AWS to host a static website in S3 for my daughter. The HTML &  styling was generated by ChatGPT, the purpose was to practice using AWS.",
      link: "https://smd-bucket-1690639694.s3.amazonaws.com/index.html",
    },
    {
      title: "Meals & Recipes (iOS)",
      desc: "iOS app for meal preparation to help plan dinner for my family. The recipe data is pulled from a local json file. Built with SwiftUI.",
      link: "https://github.com/escosio/MealsAndRecipes",
    },
  ];

  return (
    <div id="projects" className="section project-card-container">
      <h2>
        Projects <i class="fa-solid fa-code-branch"></i>
      </h2>
      <p>Here are some things I have been working on:</p>
      {projects.map((project, i) => (
        <a href={project.link} key={i} target="_blank">
          <div key={i} className="project-card">
            <h3>{project.title}</h3>
            <p>{project.desc}</p>
          </div>
        </a>
      ))}
    </div>
  );
};
